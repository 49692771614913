/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link, Notification } from "gatsby-interface"
import Layout from "../../layout"
import SiteHead from "../../head"
import PageWithSidebar from "../../components/page-with-sidebar"
import { DocsSidebarNav } from "../../components/sidebar/docs-sidebar-nav"
import {
  SubsectionList,
  LinkList,
  findCurrentDocsPage,
  PageHeader,
} from "../../components/docs/docs-type-landing-section.js"
import allDocs from "../../components/sidebar/all-docs-sidebar.yaml"
import Container from "../../components/container"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"
import subnav from "./subnav"
import StructuredData from "./structured-data"
import { getOgImage } from "./get-og-image"

export function Head(props) {
  const currentPage = findCurrentDocsPage(
    allDocs[0],
    props.pageContext.section.to
  )
  const description =
    currentPage.shortDescription || currentPage.longDescription

  return (
    <SiteHead
      {...props}
      title={currentPage.label}
      description={description}
      image={getOgImage(props.location)}
    >
      <StructuredData {...props} />
    </SiteHead>
  )
}

function DocsSection({
  location,
  pageContext: {
    section: { to },
  },
}) {
  const currentPage = findCurrentDocsPage(allDocs[0], to)

  return (
    <Layout subnav={subnav}>
      <PageWithSidebar location={location} SidebarComponent={DocsSidebarNav}>
        <SkipNavTarget />
        <PageHeader
          emphasizedColor={currentPage.emphasizedColor}
          titleText={currentPage.label}
          description={
            currentPage.longDescription || currentPage.shortDescription
          }
          location={location}
        />
        <Container>
          {(location.pathname === "/docs/tutorial/getting-started" ||
            location.pathname === "/docs/tutorial/getting-started/") && (
            <Notification
              css={theme => ({ marginBottom: theme.space[7] })}
              tone="BRAND"
              variant="SECONDARY"
              content={
                <span>
                  This tutorial is intended to be as accessible as possible to
                  people without much web development experience. If you prefer
                  to jump straight to code, feel free to skip to{" "}
                  <Link
                    to="/docs/quick-start"
                    css={theme => ({
                      fontWeight: theme.fontWeights.bold,
                    })}
                  >
                    the quick start
                  </Link>
                  .
                </span>
              }
            />
          )}
          {currentPage.subSections ? (
            <SubsectionList
              currentPage={currentPage}
              advanced={currentPage.subItems.find(
                item => item?.itemType === `additional`
              )}
            />
          ) : (
            <LinkList
              items={currentPage.subItems.filter(item => !item.itemType)} // exclude both overview & additional
              advanced={currentPage.subItems.find(
                item => item?.itemType === `additional`
              )}
            />
          )}
        </Container>
      </PageWithSidebar>
    </Layout>
  )
}

export default DocsSection
